import { datadogRum } from "@datadog/browser-rum";

export function parseQueryString(str) {
  const query = {};
  if (!str) {
    return query;
  }
  let offset = 1;
  if (!str.startsWith("?")) {
    offset = 0;
  }
  const pairs = str.substring(offset).split("&");
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split("=");
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
  }
  return query;
}

export function getInviteCode() {
  const oldStyleUrl = window.location.href.match(
    /https:\/\/reviews.widewail.com\/#\/(\w+)/
  );
  if (oldStyleUrl) {
    return oldStyleUrl[1];
  }
  return window.location.pathname.slice(1) || "";
}

export function getSource() {
  const params = parseQueryString(window.location.search);
  return params.src || "Unknown";
}

export function preventPopup(timeout) {
  clearTimeout(timeout);
  timeout = null;
  window.removeEventListener("pagehide", preventPopup);
}

function openFacebook(site, platform) {
  let fbAppUrl;
  switch (platform.type) {
    case "ios":
      fbAppUrl = `fb://page/?id=${site.thirdpartyId}`;
      break;
    default:
      // On android the facebook app seems to pick
      // up the fallback url instead of this one
      fbAppUrl = `fb://page/${site.thirdpartyId}`;
      break;
  }

  window.addEventListener("pagehide", preventPopup);
  document.addEventListener("pagehide", preventPopup);

  if (fbAppUrl) {
    console.log("opening fb app " + fbAppUrl);
    datadogRum.addAction("source_select", {
      site: "FACEBOOK",
      target: fbAppUrl,
    });

    // try opening FB APP url in iframe so we don't show error dialogs
    // if the app isn't installed
    // var iframe = document.createElement("iframe");
    // document.body.appendChild(iframe);
    // iframe.setAttribute("style", "display:none;");
    // iframe.src = fbAppUrl;

    // 9/28/2020 - sneaking the app load in an iframe was no longer working
    // just open the app directly in this window so the user sees the
    //"are you sure" dialog on ios
    window.location = fbAppUrl;

    setTimeout(function() {
      //this fallback will catch if the app doesn't open or the user clicks cancel
      window.location = site.url;
    }, 1000);
  } else {
    datadogRum.addAction("source_select", {
      site: "FACEBOOK",
      target: site.url,
    });
    window.location = site.url;
  }
}

export function buildReviewTarget(site, platform) {
  if ("FACEBOOK" === site.name.toUpperCase()) {
    return () => openFacebook(site, platform);
  } else {
    return site.url;
  }
}

export const replacePlaceholders = (string = "", data) =>
  string
    .replace(/(?:\[CUSTOMER FIRST NAME\])/g, data?.lead.firstName || "")
    .replace(/(?:\[CUSTOMER LAST NAME\])/g, data?.lead.lastName || "")
    .replace(
      /(?:\[EMPLOYEE FIRST NAME\])/g,
      data?.representatives[0]?.firstName || ""
    )
    .replace(
      /(?:\[EMPLOYEE LAST NAME\])/g,
      data?.representatives[0]?.lastName || ""
    )
    .replace(/(?:\[LOCATION\])/g, data?.customer.companyName || "")
    .replace(/(?:\[SALUTATION\])/g, data?.lead.salutation || "");

export const validEmail = (value) =>
  /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]$)/i.test(
    value
  );

export const validMobile = (value) => /^[0-9]{10,10}$/.test(value);
