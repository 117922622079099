// noinspection ES6UnusedImports

import { datadogRum } from "@datadog/browser-rum";
import React, { lazy, Suspense, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import { CompatRouter } from "react-router-dom-v5-compat";
import { lookupCode } from "./api/inviteApi";
import "./App.scss";
import { getInviteCode } from "./utils/utils";
import ErrorContent from "./views/ErrorPage";
import InviteLandingPage from "./views/InviteLandingPage";
import LoadingContent from "./views/Loading";
import NoInviteContent from "./views/NoInvite";
import TagManager from "react-gtm-module";
import { recordOpen } from "./api/inviteApi";

import "./logger"; // do not delete! needed to start Datadog

const Instructions = lazy(() => import("./views/Instructions"));
const VideoRecording = lazy(() => import("./views/VideoRecording"));
const Uploading = lazy(() => import("./views/Uploading"));
const ThankYou = lazy(() => import("./views/ThankYou"));

function App() {
  const [code] = useState(getInviteCode());
  const [media, setMedia] = useState();
  const [error, setError] = useState(false);
  const [data, setData] = useState();
  const [mediaDest, setMediaDest] = useState();
  const [videoUploaded, setVideoUploaded] = useState(false);
  const { t, i18n } = useTranslation("", { useSuspense: false });
  const changeLanguage = useCallback((lng) => i18n.changeLanguage(lng), [i18n]);
  //record open event on page load
  useEffect(() => {
    if (code) {
      recordOpen(code).catch((error) => console.error(error));
    }
  }, [code]);
  useEffect(() => {
    if (data) {
      const tagManagerArgs = {
        dataLayer: {
          event: "inviteData",
          language: data?.lng,
          lead: data?.lead?.id,
          customer: data?.customer?.companyName,
          interaction: data?.interactionId,
          inviteType: data?.type,
        },
        events: {
          sendInviteInfo: "inviteData",
        },
      };
      TagManager.dataLayer(tagManagerArgs);
    }
  }, [data]);

  useEffect(() => {
    if (data && data.lng) {
      changeLanguage(data.lng);
    }
  }, [data, changeLanguage]);

  useEffect(() => {
    datadogRum.setGlobalContext({ inviteCode: code });
    async function lookup() {
      try {
        const data = await lookupCode(code);
        setData(data);
        if (/^VIDEO/.test(data.type)) {
          window.location.hash = "#/v";
        }
        datadogRum.setGlobalContext({
          inviteCode: code,
          reviewLeadId: data.lead?.id,
        });
      } catch (err) {
        console.log(err);
        console.log(err.status);
        setError(true);
      }
    }
    lookup();
  }, [code]);

  if (error) {
    return <ErrorContent t={t} />;
  } else if (code === "") {
    return <NoInviteContent t={t} />;
  } else if (data) {
    return (
      <div className="App">
        <Suspense fallback={<LoadingContent t={t} />}>
          <Router>
            <CompatRouter>
              <Switch>
                <Route exact path="/v">
                  <Instructions
                    data={data}
                    code={code}
                    getVideo={setMedia}
                    setVideoUploaded={setVideoUploaded}
                    changeLanguage={changeLanguage}
                    t={t}
                  />
                </Route>
                <Route exact path="/videorecording" name="record">
                  <VideoRecording
                    data={data}
                    getVideo={setMedia}
                    setVideoUploaded={setVideoUploaded}
                    t={t}
                  />
                </Route>
                <Route exact path="/uploading" name="upload">
                  <Uploading
                    data={data}
                    code={code}
                    media={media}
                    mediaDest={mediaDest}
                    setMediaDest={setMediaDest}
                    videoUploaded={videoUploaded}
                    setVideoUploaded={setVideoUploaded}
                    t={t}
                    setError={setError}
                  />
                </Route>
                <Route exact path="/thankyou" name="thank">
                  <ThankYou data={data} code={code} media={media} t={t} />
                </Route>
                <Route path="*">
                  <InviteLandingPage
                    data={data}
                    code={code}
                    changeLanguage={changeLanguage}
                    t={t}
                  />
                </Route>
              </Switch>
            </CompatRouter>
          </Router>
        </Suspense>
      </div>
    );
  } else {
    return <LoadingContent t={t} />;
  }
}

export default App;
